<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Media Viewers">
                    <template v-slot:description>
                        <div>
                            <div>import { HbMediaViewer }  from 'hummingbird-aviary';</div>
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card class="mt-4 mb-6">
            <template v-slot:title>
                Examples + Codes
            </template>

            <hb-form label="Open Media Viewer Manually">
                <div class="mt-2">
                    <HbBtn small @click="mediaViewer = true">Open</HbBtn>
                </div>
            </hb-form>

            <hb-media-viewer
                grid
                uploader
                v-model="mediaViewer"
                @upload="handleUploadedFiles"
            >

                <template v-slot:slides>
                    <slide
                        v-for="(upload, i) in uploads"
                        :key="'upload_' + i"
                        :id="'temp_upload_id_' + i"
                        :src="upload.src"
                    />
                    <slide
                        v-for="(slide, i) in slides"
                        :key="'slide_' + i"
                        :id="slide.id"
                        :video="slide.video"
                        :src="slide.src"
                        :thumbnail="slide.thumbnail"
                    />
                </template>
            </hb-media-viewer>

            <!-- @delete="deleteImage" -->
<!--
            <hb-media-viewer grid v-model="mediaViewer">

                <template v-slot:slides>
                    <slide
                        id="0123456"
                        src="https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg"
                    />
                    <slide
                        id="1234567"
                        :video="true"
                        src="https://videos.pexels.com/video-files/4772983/4772983-sd_360_640_24fps.mp4"
                        thumbnail="https://images.pexels.com/videos/4772983/pexels-photo-4772983.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                    />
                    <slide
                        id="2345678"
                        src="https://cdn.vuetifyjs.com/images/carousel/bird.jpg"
                    />
                    <slide
                        id="3456789"
                        src="https://cdn.vuetifyjs.com/images/carousel/planet.jpg"
                    />
                    <slide
                        id="4567890"
                        :video="true"
                        src="https://videos.pexels.com/video-files/4874384/4874384-sd_640_360_30fps.mp4"
                        thumbnail="https://images.pexels.com/videos/4874384/4k-8-alberi-animale-4874384.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                    />
                    <slide
                        id="5678901"
                        src="https://cdn.vuetifyjs.com/images/carousel/sky.jpg"
                    />
                    <slide
                        id="012345a"
                        src="https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg"
                    />
                    <slide
                        id="123456a"
                        :video="true"
                        src="https://videos.pexels.com/video-files/4772983/4772983-sd_360_640_24fps.mp4"
                        thumbnail="https://images.pexels.com/videos/4772983/pexels-photo-4772983.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                    />
                    <slide
                        id="234567a"
                        src="https://cdn.vuetifyjs.com/images/carousel/bird.jpg"
                    />
                    <slide
                        id="345678a"
                        src="https://cdn.vuetifyjs.com/images/carousel/planet.jpg"
                    />
                    <slide
                        id="456789a"
                        :video="true"
                        src="https://videos.pexels.com/video-files/4874384/4874384-sd_640_360_30fps.mp4"
                        thumbnail="https://images.pexels.com/videos/4874384/4k-8-alberi-animale-4874384.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                    />
                    <slide
                        id="567890a"
                        src="https://cdn.vuetifyjs.com/images/carousel/sky.jpg"
                    />
                </template>
            </hb-media-viewer>
        -->

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-media-viewer
    grid
    uploader
    v-model="mediaViewer"
    @upload="handleUploadedFiles"
>
    &lt;template v-slot:slides>
        &lt;slide
            v-for="(upload, i) in uploads"
            :key="'upload_' + i"
            :id="'temp_upload_id_' + i"
            :src="upload.src"
        />
        &lt;slide
            v-for="(slide, i) in slides"
            :key="'slide_' + i"
            :id="slide.id"
            :video="slide.video"
            :src="slide.src"
            :thumbnail="slide.thumbnail"
        />
    &lt;/template>
&lt;/hb-media-viewer>

uploads: {{ uploads }}

slides: {{ slides }}

handleUploadedFiles(files){
    Array.from(files).forEach(file => {
        if(file.size > 10000000){
            this.list.push('Failed: "' + file.name + '" is larger than the allowed maximum file size of 10 MB.');
            this.snackbar = true;
        } else {
            const reader = new FileReader();
            reader.onload = (e) => {
            this.uploads.unshift({
                src: e.target.result,
                file: file,
            });
            };
            reader.readAsDataURL(file);
        }
    });
}
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Props" class="mb-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="snackbar"
            type="error"
            :list="list"
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemMediaViewer",
        data: function() {
            return {
                mediaViewer: false,
                slides: [
                    {
                        id: '0123456',
                        video: false,
                        src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
                        thumbnail: '',
                    },
                    {
                        id: '1234567',
                        video: true,
                        src: 'https://videos.pexels.com/video-files/4772983/4772983-sd_360_640_24fps.mp4',
                        thumbnail: 'https://images.pexels.com/videos/4772983/pexels-photo-4772983.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
                    },
                    {
                        id: '2345678',
                        video: false,
                        src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
                        thumbnail: '',
                    },
                    {
                        id: '3456789',
                        video: false,
                        src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
                        thumbnail: '',
                    },
                    {
                        id: '4567890',
                        video: true,
                        src: 'https://videos.pexels.com/video-files/4874384/4874384-sd_640_360_30fps.mp4',
                        thumbnail: 'https://images.pexels.com/videos/4874384/4k-8-alberi-animale-4874384.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
                    },
                    {
                        id: '5678901',
                        video: false,
                        src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
                        thumbnail: '',
                    },
                    /*
                    {
                        id: '012345a',
                        video: false,
                        src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
                        thumbnail: '',
                    },
                    {
                        id: '123456a',
                        video: true,
                        src: 'https://videos.pexels.com/video-files/4772983/4772983-sd_360_640_24fps.mp4',
                        thumbnail: 'https://images.pexels.com/videos/4772983/pexels-photo-4772983.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
                    },
                    {
                        id: '234567a',
                        video: false,
                        src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
                        thumbnail: '',
                    },
                    {
                        id: '345678a',
                        video: false,
                        src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
                        thumbnail: '',
                    },
                    {
                        id: '456789a',
                        video: true,
                        src: 'https://videos.pexels.com/video-files/4874384/4874384-sd_640_360_30fps.mp4',
                        thumbnail: 'https://images.pexels.com/videos/4874384/4k-8-alberi-animale-4874384.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
                    },
                    {
                        id: '567890a',
                        video: false,
                        src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
                        thumbnail: '',
                    }
                    */
                ],
                uploads: [],
                propHeaders: [
                    { text: 'Name', value: 'name', width: '110' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: '', type: 'boolean', default: 'false', description: '' },
                    { name: '', type: 'string', default: 'undefined', description: '' },
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@click', description: '' },
                ],
                description: '',
                snackbar: false,
                list: [],
                // goToId: '',
            };
        },
        methods: {
            handleClick(){
                this.description = 'The "@click" event was initiated.';
                this.snackbar = true;
            },
            deleteImage(imageId){
                console.log(imageId);
            },
            handleUploadedFiles(files){
                Array.from(files).forEach(file => {
                    if(file.size > 10000000){
                        this.list.push('Failed: "' + file.name + '" is larger than the allowed maximum file size of 10 MB.');
                        this.snackbar = true;
                    } else {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                        this.uploads.unshift({
                            src: e.target.result,
                            file: file,
                            /*
                            caption: '',
                            is_thumnail: 0,
                            */
                        });
                        };
                        reader.readAsDataURL(file);
                    }
                });
            }
        },
        watch: {
            snackbar(){
                if(!this.snackbar){
                    this.list = [];
                }
            }
        }
    }
</script>

<style scoped>

</style>
